import NextImage, {StaticImageData} from 'next/image';
import classNames from 'classnames';
import {HeroIntro} from '@components/HeroIntro';

import styles from './HeroIntroBackroundMTD.module.scss';

type HeroIntroBackroundMTDProps = {
    bgImg: StaticImageData;
    bgImgTablet: StaticImageData;
    bgImgMobile: StaticImageData;
    alt: string;
};

export const HeroIntroBackroundMTD = ({bgImg, bgImgTablet, bgImgMobile, alt}: HeroIntroBackroundMTDProps) => (
    <HeroIntro.Background>
        <span className={classNames(styles.hideTabletMobile)}>
            <NextImage
                placeholder="blur"
                alt={alt}
                src={bgImg}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </span>
        <span className={classNames(styles.hideDesktopMobile)}>
            <NextImage
                placeholder="blur"
                src={bgImgTablet}
                alt={alt}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </span>
        <span className={classNames(styles.hideTabletDesktop)}>
            <NextImage
                placeholder="blur"
                src={bgImgMobile}
                alt={alt}
                fill
                sizes="100vw"
                style={{
                    objectFit: 'cover',
                }}
            />
        </span>
    </HeroIntro.Background>
);
